import React, { useState } from "react";
import {
  PlusOutlined,
  DeleteFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Upload, message } from "antd";

import { parseResume, createCandidate } from "../api/CandidateManagementAPI";

interface AddCandidateFormProps {
  visible: boolean;
  onCancel: () => void;
}

const CandidateAdd: React.FC<AddCandidateFormProps> = ({
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resumeFile, setResumeFile] = useState<File | null>(null);

  const handleOk = async () => {
    form
      .validateFields()
      .then((values) => {
        return handleAddCandidate(values);
      })
      .catch((info) => {
        message.error("Please complete all required fields");
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setResumeFile(null);
    onCancel();
  };

  const handleAddCandidate = async (values: any) => {
    try {
      setIsLoading(true);
      await createCandidate({ ...values, resume: resumeFile });
      message.success(`Candidate succesfully created`);
      window.location.reload();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      message.error(`Failed to parse resume : ${error.response.data.message}`);
      console.error("Error parsing resume:", error);
    }
  };

  const handleResumeChange = async (info: any) => {
    if (isLoading) return;

    const { file } = info;
    setResumeFile(file.originFileObj);

    try {
      setIsLoading(true);
      const result = await parseResume(file.originFileObj);

      form.setFieldValue("candidateName", result.data.name);
      form.setFieldValue("jobTitle", result.data["job-title"]);
      form.setFieldValue("email", result.data.email);
      form.setFieldValue("phone", result.data.phone);
      form.setFieldValue("aboutMe", result.data["about-me"]);
      form.setFieldValue("skills", result.data.skills);
      form.setFieldValue("education", result.data.education);
      form.setFieldValue(
        "workingExperiences",
        result.data["working-experiences"]
      );
      form.setFieldValue("languages", result.data.languages);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      message.error(`Failed to parse resume : ${error.message}`);
      console.error("Error parsing resume:", error);
    }
  };

  return (
    <Modal
      open={visible}
      width={1200}
      title={"Add Candidate"}
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 24,
            gap: 12,
          }}
        >
          <Upload
            name="logo"
            showUploadList={false}
            onChange={handleResumeChange}
          >
            <Button icon={<PlusOutlined />} loading={isLoading}>
              Click to Upload Resume (.pdf)
            </Button>
          </Upload>
        </div>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="candidateName"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please complete the candidate name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please input the phone number!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="jobTitle"
              label="Job Title"
              rules={[
                {
                  required: true,
                  message: "Please input the job title",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="aboutMe" label="About Me">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            borderTop: "1px solid black",
            padding: "20px 0",
            fontWeight: "600",
          }}
        >
          Skills
        </div>

        <Form.List
          name="skills"
          initialValue={form.getFieldValue("skills") || []}
          rules={[
            {
              validator: async (_, skills) => {
                if (!skills || skills.length < 1) {
                  return Promise.reject(
                    new Error("At least one skill is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <Row
                  key={key}
                  gutter={16}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item
                      name={[name, "skill"]}
                      label="Skill"
                      rules={[
                        { required: true, message: "Please input the skill!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={5} md={5}>
                    <Form.Item name={[name, "proficiency"]} label="Proficiency">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} md={4}>
                    <Button
                      icon={<DeleteFilled />}
                      size="small"
                      style={{
                        color: "red",
                      }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Skill
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <div
          style={{
            borderTop: "1px solid black",
            padding: "20px 0",
            fontWeight: "600",
          }}
        >
          Educations
        </div>

        <Form.List
          name="education"
          initialValue={form.getFieldValue("education") || []}
          rules={[
            {
              validator: async (_, education) => {
                if (!education || education.length < 1) {
                  return Promise.reject(
                    new Error("At least one education is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <>
                  <Row
                    key={key}
                    gutter={16}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col xs={24} sm={10} md={10}>
                      <Form.Item
                        name={[name, "institution"]}
                        label="Institution"
                        rules={[
                          {
                            required: true,
                            message: "Please input the institution !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                      <Form.Item
                        name={[name, "degree"]}
                        label="Degree"
                        rules={[
                          {
                            required: true,
                            message: "Please input the degree !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={10}>
                      <Form.Item
                        name={[name, "fieldOfStudy"]}
                        label="Major"
                        rules={[
                          {
                            required: true,
                            message: "Please input the major !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "startMonth"]}
                        label="Start Month"
                        rules={[
                          {
                            required: true,
                            message: "Please input the start month !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "startYear"]}
                        label="Start Year"
                        rules={[
                          {
                            required: true,
                            message: "Please input the start year !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "endMonth"]}
                        label="End Month"
                        rules={[
                          {
                            required: true,
                            message: "Please input the end month !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "endYear"]}
                        label="End Year"
                        rules={[
                          {
                            required: true,
                            message: "Please input the end year !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ))}
              <Row
                gutter={24}
                style={{
                  marginBottom: "16px",
                  alignItems: "center",
                }}
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Education
                </Button>
              </Row>
            </>
          )}
        </Form.List>

        <div
          style={{
            borderTop: "1px solid black",
            padding: "20px 0",
            fontWeight: "600",
          }}
        >
          Working Experiences
        </div>

        <Form.List
          name="workingExperiences"
          initialValue={form.getFieldValue("workingExperiences") || []}
          rules={[
            {
              validator: async (_, workingExperiences) => {
                if (!workingExperiences || workingExperiences.length < 1) {
                  return Promise.reject(
                    new Error("At least one working experience is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <>
                  <Row
                    key={key}
                    gutter={16}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col xs={24} sm={10} md={10}>
                      <Form.Item
                        name={[name, "company"]}
                        label="Company"
                        rules={[
                          {
                            required: true,
                            message: "Please input the company name !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={10}>
                      <Form.Item name={[name, "location"]} label="Location">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={10}>
                      <Form.Item
                        name={[name, "jobTitle"]}
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "Please input the role !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "startMonth"]}
                        label="Start Month"
                        rules={[
                          {
                            required: true,
                            message: "Please input the role !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "startYear"]}
                        label="Start Year"
                        rules={[
                          {
                            required: true,
                            message: "Please input the role !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "endMonth"]}
                        label="End Month"
                        rules={[
                          {
                            required: true,
                            message: "Please input the role !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Item
                        name={[name, "endYear"]}
                        label="End Year"
                        rules={[
                          {
                            required: true,
                            message: "Please input the end year !",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.List name={[name, "responsibilities"]}>
                        {(fields, { add, remove }) => (
                          <>
                            <label>Responsibilities</label>
                            {fields.map(({ key, name, ...restField }) => (
                              <Form.Item
                                key={key}
                                {...restField}
                                name={name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter a responsibility",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter responsibility"
                                  addonAfter={
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  }
                                />
                              </Form.Item>
                            ))}
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add Responsibility
                            </Button>
                            <Col
                              xs={24}
                              sm={4}
                              md={4}
                              style={{ margin: "10px 0" }}
                            >
                              <Button
                                icon={<DeleteFilled />}
                                size="small"
                                style={{
                                  color: "red",
                                }}
                                onClick={() => remove(name)}
                              >
                                Delete Working Experience
                              </Button>{" "}
                            </Col>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                  <div
                    style={{
                      borderTop: "1px dashed black",
                      padding: "20px 0",
                      fontWeight: "600",
                    }}
                  ></div>
                </>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Working Experience
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <div
          style={{
            borderTop: "1px solid black",
            padding: "20px 0",
            fontWeight: "600",
          }}
        >
          Languages
        </div>

        <Form.List
          name="languages"
          initialValue={form.getFieldValue("languages") || []}
          rules={[
            {
              validator: async (_, languages) => {
                if (!languages || languages.length < 1) {
                  return Promise.reject(
                    new Error("At least one language is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <Row
                  key={key}
                  gutter={16}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item
                      name={[name, "language"]}
                      label="Language"
                      rules={[
                        {
                          required: true,
                          message: "Please input the language !",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={5} md={5}>
                    <Form.Item name={[name, "proficiency"]} label="Proficiency">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} md={4}>
                    <Button
                      icon={<DeleteFilled />}
                      size="small"
                      style={{
                        color: "red",
                      }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Language
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default CandidateAdd;
