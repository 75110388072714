import { useState } from "react";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Typography, Select } from "antd";
import { MdAdd, MdDownload } from "react-icons/md";

import CandidateAdd from "./view/CandidateAdd";
import {
  downloadCandidateList,
  getCandidates,
} from "./api/CandidateManagementAPI";
import useSpecializationOptions from "src/hooks/useSpecializationOption";
import useLocationOptions from "src/hooks/useLocationOption";
import { ICandidate } from "./type/CandidateManagement";

const CandidateManagement = () => {
  const { Title } = Typography;

  const { specializationOptions } = useSpecializationOptions();
  const { locationOptions } = useLocationOptions();

  const [visible, setVisible] = useState(false);

  const waitTime = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const showModal = () => {
    setVisible(true);
  };

  const specializationEnum = specializationOptions?.reduce(
    (acc, specialization) => {
      // @ts-expect-error ignore
      acc[specialization] = { text: specialization };
      return acc;
    },
    {}
  );

  const formattedDate = (date: Date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const columns: ProColumns<ICandidate>[] = [
    {
      title: "Name",
      dataIndex: "candidateName",
      key: "candidateName",
      width: 200,
      render: (text, record) => (
        <a
          href={
            record.externalSource
              ? `/users/candidate/external/${record._id}`
              : `/users/candidate/${record._id}`
          }
        >
          {record.candidateName}
        </a>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      valueEnum: {
        internal: { text: "Internal" },
        admin: { text: "Admin" },
        manatal: { text: "Manatal" },
      },
      render: (text, record) => (
        <div>{record.externalSource ? record.externalSource : "Internal"}</div>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Email",
      key: "email",
      render: (text, record) => (
        <div>
          {record.externalSource
            ? record.email || "-"
            : record.userId?.[0]?.email || "-"}
        </div>
      ),
    },
    {
      title: "Contact No",
      key: "phone",
      render: (text, record) => (
        <div>
          {" "}
          {record.externalSource
            ? record.phone || "-"
            : record.userId?.[0]?.phone || "-"}
        </div>
      ),
    },
    {
      title: "Salary Expectation",
      dataIndex: "salaryExpectationAmount",
      key: "salaryExpectationAmount",
      valueType: "digit",
      hideInSearch: true,
    },
    {
      title: "Min. Salary Expectation",
      key: "minSalaryExpectationAmount",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Max. Salary Expectation",
      key: "maxSalaryExpectationAmount",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Total Experience (years)",
      dataIndex: "totalExperience",
      key: "totalExperience",
      hideInSearch: true,
      render: (text, record) => <>{record?.totalExperience.toFixed(1)}</>,
    },
    {
      title: "Min. Total Expectation (year)",
      key: "minTotalExperience",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Max. Total Experience (year)",
      key: "maxTotalExperience",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Specializations",
      key: "specializations",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
      },
      valueEnum: specializationEnum,
    },
    {
      title: "Authorized to Work in Malaysia",
      dataIndex: "authorizedToWorkInMalaysia",
      key: "authorizedToWorkInMalaysia",
      valueEnum: {
        Yes: { text: "Yes" },
        No: { text: "No" },
      },
      render: (text, record) => (
        <>{record?.authorizedToWorkInMalaysia ? "Yes" : "No"}</>
      ),
    },
    {
      title: "State",
      dataIndex: "locationState",
      key: "locationState",
      valueType: "select",
      renderFormItem: (_, { defaultRender }, form) => {
        return (
          <Select
            placeholder="Select State"
            onChange={(value) => {
              form.setFieldValue("locationState", value);
              form.setFieldValue("locationCity", undefined);
            }}
          >
            {locationOptions &&
              Object.keys(locationOptions).map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "City",
      dataIndex: "locationCity",
      key: "locationCity",
      renderFormItem: (_, { defaultRender, ...rest }, form) => {
        const selectedState = form.getFieldValue("locationState");

        const cities =
          selectedState && locationOptions
            ? locationOptions[selectedState]
            : [];

        return (
          <Select
            placeholder="Select City"
            disabled={!selectedState}
            key={selectedState || "empty"}
          >
            {cities.map((city: string) => (
              <Select.Option key={city} value={city}>
                {city}
              </Select.Option>
            ))}
          </Select>
        );
      },
      dependencies: ["locationState"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{formattedDate(new Date(record.createdAt))}</div>
      ),
      valueType: "dateRange",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <CandidateAdd visible={visible} onCancel={() => setVisible(false)} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Candidate Management
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "10px",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Candidate
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadCandidateList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getCandidates({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 0,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};

export default CandidateManagement;
