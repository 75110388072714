import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { Button, message, Typography, Select } from "antd";
import { useState } from "react";
import { MdAdd, MdDownload } from "react-icons/md";

import { createJob, downloadJobList, getJobs } from "./api/JobManagementAPI";
import { IJob } from "./type/JobManagement";
import JobAdd from "./view/JobAdd";
import getRelativeTime from "src/utils/getRelativeTime";
import useLocationOptions from "src/hooks/useLocationOption";

const { Title } = Typography;

const JobManagement = () => {
  const { locationOptions } = useLocationOptions();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createJobMutation = useMutation(createJob, {
    onSuccess: (data) => {
      message.success("Job created successfully!");
      setVisible(false);
      window.location.reload();
    },
    onError: (error: Error) => {
      message.error(`Failed to create job: ${error.message}`);
    },
  });

  const handleCreate = (values: IJob) => {
    createJobMutation.mutateAsync(values);
  };

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const formattedDate = (date: Date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const columns: ProColumns<IJob>[] = [
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text, record) => (
        <a href={`/job/${record.id}`}>{record.jobTitle}</a>
      ),
    },
    {
      title: "Company Name",
      dataIndex: ["company", "companyName"],
      key: "companyName",
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
      valueEnum: {
        "Full-Time": { text: "Full-Time" },
        "Part-Time": { text: "Part-Time" },
        Contract: { text: "Contract" },
        Internship: { text: "Internship" },
        Other: { text: "Other" },
      },
    },
    {
      title: "State",
      dataIndex: "locationState",
      key: "locationState",
      valueType: "select",
      renderFormItem: (_, { defaultRender }, form) => {
        return (
          <Select
            placeholder="Select State"
            onChange={(value) => {
              form.setFieldValue("locationState", value);
              form.setFieldValue("locationCity", undefined);
            }}
          >
            {locationOptions &&
              Object.keys(locationOptions).map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "City",
      dataIndex: "locationCity",
      key: "locationCity",
      renderFormItem: (_, { defaultRender, ...rest }, form) => {
        const selectedState = form.getFieldValue("locationState");

        const cities =
          selectedState && locationOptions
            ? locationOptions[selectedState]
            : [];

        return (
          <Select
            placeholder="Select City"
            disabled={!selectedState}
            key={selectedState || "empty"}
          >
            {cities.map((city: string) => (
              <Select.Option key={city} value={city}>
                {city}
              </Select.Option>
            ))}
          </Select>
        );
      },
      dependencies: ["locationState"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      valueType: "select",
      valueEnum: {
        open: { text: "Open", status: "Success" },
        paused: { text: "Paused", status: "Warning" },
        expired: { text: "Expired", status: "Error" },
        drafted: { text: "Drafted", status: "Default" },
      },
    },
    {
      title: "Expiration",
      dataIndex: "endAt",
      key: "endAt",
      valueType: "dateRange",
      sorter: true,
      render: (text, record) => (
        <div>{record.endAt ? formattedDate(new Date(record.endAt)) : "-"}</div>
      ),
    },
    {
      title: "WorkCRUD picks applicants",
      dataIndex: "numberOfWorkcrudpickApplicants",
      key: "numberOfWorkcrudpickApplicants",
    },
    {
      title: "Applicants target",
      dataIndex: "guaranteedApplicantsTarget",
      key: "guaranteedApplicantsTarget",
    },
    {
      title: "Total Applicants",
      dataIndex: "numberOfApplicants",
      key: "numberOfApplicants",
    },
    {
      title: "Min. Experience (years)",
      dataIndex: "minExperience",
      key: "minExperience",
      valueType: "digit",
    },
    {
      title: "Max. Experience (years)",
      key: "maxExperience",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Salary (RM)",
      key: "salaryMinAmount",
      valueType: "digit",
      hideInSearch: true,
      render: (text, record) => (
        <div>
          {record.salaryMinAmount} - {record.salaryMaxAmount}
        </div>
      ),
    },
    {
      title: "Min. Salary",
      key: "minSalary",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Max. Salary",
      key: "maxSalary",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{formattedDate(new Date(record.createdAt))}</div>
      ),
      valueType: "dateRange",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <JobAdd
        visible={visible}
        onSubmit={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Job Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Job
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadJobList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getJobs({ filter, sort, params });
            const jobs = data.results;

            return {
              data: jobs,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};

export default JobManagement;
