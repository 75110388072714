import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiRequest } from "src/utils/API";

export type LanguageOptions = string[];

interface DataReturn {
  languageOptions: LanguageOptions | undefined;
  languageOptionsLoading: boolean;
}

export const getLanguageOption = async () => {
  const response = await apiRequest<LanguageOptions>("GET", `/v1/options/languages`);
  return response.data;
};

function useLanguageOptions(): DataReturn {
  const [languageOptions, setLanguageOptions] = useState<LanguageOptions>();

  const queryOption = useQuery<LanguageOptions>({
    queryKey: ["language-option"],
    queryFn: () => getLanguageOption(),
    onSuccess: (data) => {
      if (data) {
        setLanguageOptions(data);
      }
    },
  });

  return {
    languageOptions,
    languageOptionsLoading: queryOption.isLoading,
  };
}

export default useLanguageOptions;
