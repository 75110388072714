import { ProColumns, ProTable } from "@ant-design/pro-components";
import { Typography, Select } from "antd";

import { searchCandidates } from "./api/TalentSearchAPI";
import useSpecializationOptions from "src/hooks/useSpecializationOption";
import useSkillOptions from "src/hooks/useSkillOption";
import useLocationOptions from "src/hooks/useLocationOption";
import useLanguageOptions from "src/hooks/useLanguageOption";
import { ICandidate } from "./type/CandidateManagement";

const TalentSearch = () => {
  const { Title } = Typography;

  const { specializationOptions } = useSpecializationOptions();
  const { skillOptions } = useSkillOptions();
  const { locationOptions } = useLocationOptions();
  const { languageOptions } = useLanguageOptions();

  const waitTime = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const specializationEnum = specializationOptions?.reduce(
    (acc, specialization) => {
      // @ts-expect-error ignore
      acc[specialization] = { text: specialization };
      return acc;
    },
    {}
  );

  const skillEnum = skillOptions?.reduce((acc, skill) => {
    // @ts-expect-error ignore
    acc[skill] = { text: skill };
    return acc;
  }, {});

  const languageEnum = languageOptions?.reduce((acc, language) => {
    // @ts-expect-error ignore
    acc[language] = { text: language };
    return acc;
  }, {});

  const formattedDate = (date: Date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const columns: ProColumns<ICandidate>[] = [
    {
      title: "Name",
      dataIndex: "candidateName",
      key: "candidateName",
      width: 200,
      hideInSearch: true,
      render: (text, record) => (
        <a
          href={
            record.externalSource
              ? `/users/candidate/external/${record._id}`
              : `/users/candidate/${record._id}`
          }
        >
          {record.candidateName}
        </a>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      hideInSearch: true,
      render: (text, record) => (
        <div>{record.externalSource ? record.externalSource : "Internal"}</div>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Keyword (company name/about me/job description)",
      dataIndex: "keyword",
      key: "keyword",
      hideInTable: true,
    },
    {
      title: "Total Experience (years)",
      dataIndex: "totalExperience",
      key: "totalExperience",
      hideInSearch: true,
      render: (text, record) => <>{record?.totalExperience.toFixed(1)}</>,
    },
    {
      title: "State",
      dataIndex: "locationState",
      key: "locationState",
      valueType: "select",
      renderFormItem: (_, { defaultRender }, form) => {
        return (
          <Select
            placeholder="Select State"
            onChange={(value) => {
              form.setFieldValue("locationState", value);
              form.setFieldValue("locationCity", undefined);
            }}
          >
            {locationOptions &&
              Object.keys(locationOptions).map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "City",
      dataIndex: "locationCity",
      key: "locationCity",
      renderFormItem: (_, { defaultRender, ...rest }, form) => {
        const selectedState = form.getFieldValue("locationState");

        const cities =
          selectedState && locationOptions
            ? locationOptions[selectedState]
            : [];

        return (
          <Select
            placeholder="Select City"
            disabled={!selectedState}
            key={selectedState || "empty"}
          >
            {cities.map((city: string) => (
              <Select.Option key={city} value={city}>
                {city}
              </Select.Option>
            ))}
          </Select>
        );
      },
      dependencies: ["locationState"],
    },
    {
      title: "Min. Experience (year)",
      key: "minExperience",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Max. Experience (year)",
      key: "maxExperience",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Specializations",
      key: "specializations",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
      },
      valueEnum: specializationEnum,
    },
    {
      title: "Skills",
      key: "skills",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
      },
      valueEnum: skillEnum,
    },
    {
      title: "Languages",
      key: "languages",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
      },
      valueEnum: languageEnum,
    },
    {
      title: "Min. Salary (RM)",
      key: "minSalary",
      valueType: "digit",
      hideInTable: true,
      render: (text, record) => (
        <div>{record.externalSource ? record.externalSource : "Internal"}</div>
      ),
    },
    {
      title: "Max. Salary (RM)",
      key: "maxSalary",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Salary Amount",
      dataIndex: "salaryAmount",
      key: "salaryAmount",
      valueType: "digit",
      hideInSearch: true,
      render: (text, record) => <div>{record.salaryAmount || "-"}</div>,
    },
    {
      title: "Salary Expectation",
      dataIndex: "salaryExpectationAmount",
      key: "salaryExpectationAmount",
      valueType: "digit",
      hideInSearch: true,
      render: (text, record) => (
        <div>{record.salaryExpectationAmount || "-"}</div>
      ),
    },
    {
      title: "Min. Salary Expectation (RM)",
      key: "minSalaryExpectationAmount",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Max. Salary Expectation (RM)",
      key: "maxSalaryExpectationAmount",
      valueType: "digit",
      hideInTable: true,
    },
    {
      title: "Willing to Relocate Domestically",
      dataIndex: "willingToRelocateDomestically",
      key: "willingToRelocateDomestically",
      valueEnum: {
        true: { text: "Yes" },
        false: { text: "No" },
      },
      render: (text, record) => (
        <>{record?.willingToRelocateDomestically ? "Yes" : "No"}</>
      ),
    },
    {
      title: "Willing to RelocateOverseas",
      dataIndex: "willingToRelocateOverseas",
      key: "willingToRelocateOverseas",
      valueEnum: {
        true: { text: "Yes" },
        false: { text: "No" },
      },
      render: (text, record) => (
        <>{record?.willingToRelocateDomestically ? "Yes" : "No"}</>
      ),
    },
    {
      title: "Authorized to Work in Malaysia",
      dataIndex: "authorizedToWorkInMalaysia",
      key: "authorizedToWorkInMalaysia",
      valueEnum: {
        true: { text: "Yes" },
        false: { text: "No" },
      },
      render: (text, record) => (
        <>{record?.authorizedToWorkInMalaysia ? "Yes" : "No"}</>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      hideInSearch: true,
      render: (text, record) => (
        <div>{formattedDate(new Date(record.createdAt))}</div>
      ),
      valueType: "dateRange",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Talent Search
        </Title>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await searchCandidates({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 0,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};

export default TalentSearch;
