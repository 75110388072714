import { Route, Routes } from "react-router-dom";
import CandidateManagement from "src/pages/candidateManagement/CandidateManagement";
import CandidateDetails from "src/pages/candidateManagement/view/CandidateDetail";
import CompanyManagement from "src/pages/companyManagement/CompanyManagement";
import CompanyDetails from "src/pages/companyManagement/view/CompanyDetail";
import CompanyPackageManagement from "src/pages/companyPackageManagement/CompanyPackageManagement";
import CompanyPackageDetails from "src/pages/companyPackageManagement/view/CompanyPackageDetail";
import JobManagement from "src/pages/jobManagement/JobManagement";
import Dashboard from "src/pages/dashboard/DashboardPage";
import JobApplicationManagement from "src/pages/jobApplicationManagement/JobApplicationManagement";
import JobApplicationDetail from "src/pages/jobApplicationManagement/view/JobApplicationDetail";
import TalentSearchPackageManagement from "src/pages/talentSearchPackageManagement/TalentSearchPackageManagement";
import TalentSearchPackageDetails from "src/pages/talentSearchPackageManagement/view/TalentSearchPackageDetail";
import TransactionManagement from "src/pages/transactionManagement/TransactionManagement";
import TransactionDetails from "src/pages/transactionManagement/view/TransactionDetail";
import NotFoundPage from "src/pages/notFoundPage/notFoundPage";
import JobDetails from "src/pages/jobManagement/view/JobDetail";
import ExternalCandidateDetails from "src/pages/candidateManagement/view/ExternalCandidateDetail";
import TalentSearch from "src/pages/talentSearch/TalentSearch";

export default function RouteList() {
  const handleAdminRoutes = () => (
    <>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/users/candidate" element={<CandidateManagement />} />
      <Route path="/users/candidate/:id" element={<CandidateDetails />} />
      <Route
        path="/users/candidate/external/:id"
        element={<ExternalCandidateDetails />}
      />
      <Route path="/users/company" element={<CompanyManagement />} />
      <Route path="/users/company/:id" element={<CompanyDetails />} />
      <Route path="/talent-search" element={<TalentSearch />} />
      <Route path="/job" element={<JobManagement />} />
      <Route path="/job/:id" element={<JobDetails />} />
      <Route path="/job-application" element={<JobApplicationManagement />} />
      <Route path="/job-application/:id" element={<JobApplicationDetail />} />
      <Route path="/package" element={<CompanyPackageManagement />} />
      <Route path="/package/:id" element={<CompanyPackageDetails />} />
      <Route
        path="/package/talent-search"
        element={<TalentSearchPackageManagement />}
      />
      <Route
        path="/package/talent-search/:id"
        element={<TalentSearchPackageDetails />}
      />
      <Route path="/transaction" element={<TransactionManagement />} />
      <Route path="/transaction/:id" element={<TransactionDetails />} />
    </>
  );

  // TODO modify when API implementation is done
  const handleRolesRoutes = (roles: string) => {
    switch (roles) {
      case "operator":
        return NotFoundPage();
      case "admin":
        return handleAdminRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Routes>{handleRolesRoutes("admin")}</Routes>;
}
