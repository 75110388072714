import axios from "axios";

import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetCandidate,
} from "../type/CandidateManagement";
import formatDateTime from "src/utils/formatDateTime";
import { getToken } from "src/utils/AuthService";
import { ICandidate } from "../type/CandidateManagement";

const apiHost = process.env.REACT_APP_API_HOST;

export const getCandidates = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetCandidate>(
    "GET",
    `/v1/admin/candidate/list?${newParams}`
  );

  return response.data;
};

export const createCandidate = async (data: ICandidate) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const token = getToken();
    const response = await axios.post(`${apiHost}/v1/admin/candidate`, data, {
      headers: {
        Authorization: token ? `Bearer ${token.access.token}` : "",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCandidate = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetCandidate>(
    "GET",
    `/v1/admin/candidate/${id}`
  );
  return response.data;
};

export const downloadCandidateList = async () => {
  const fileName = `Candidate_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/candidate/list/download", fileName);
};

export const parseResume = async (resume: string | Blob) => {
  const formData = new FormData();
  formData.append("resume", resume);

  const token = getToken();
  const response = await axios.post(`${apiHost}/v1/resume-parser`, formData, {
    headers: {
      Authorization: token ? `Bearer ${token.access.token}` : "",
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const editCandidate = async ({
  internalNote,
  candidateId,
}: {
  internalNote: string;
  candidateId: string;
}) => {
  return await apiRequest<any>("PATCH", `/v1/admin/candidate/${candidateId}`, {
    internalNote,
  });
};
