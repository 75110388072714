import React from "react";
import { Col, Form, Input, Modal, Row } from "antd";

const { TextArea } = Input;

interface CandidateAddInternalNoteFormProps {
  visible: boolean;
  onCreate: (values: string) => void;
  onCancel: () => void;
  initialData?: string;
  isEdit?: boolean;
}

const CandidateAddInternalNote: React.FC<CandidateAddInternalNoteFormProps> = ({
  visible,
  onCreate,
  onCancel,
  isEdit = false,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.internalNote.length > 300) {
          alert("Max. 300 characters");
          return;
        }
        onCreate({ ...values });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={visible}
      width={500}
      title={"Add Internal Note"}
      okText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item name="internalNote" label="Internal Note">
              <TextArea
                rows={6}
                placeholder="Enter internal note for candidate (only appears to admin)"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CandidateAddInternalNote;
